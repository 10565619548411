import React from 'react';
import { SVGIcon } from './svgHelpers';

export const QuestionMark: SVGIcon = (props) => {
  const {
    className,
    'data-testid': dataTestID = 'question-mark-icon',
    ...otherProps
  } = props;
  return (
    <svg
      data-testid={dataTestID}
      className={`fill-current ${className}`}
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="m7.25 12.5h1.5v-1.5h-1.5zm.75-12c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zm0 13.5c-3.3075 0-6-2.6925-6-6s2.6925-6 6-6 6 2.6925 6 6-2.6925 6-6 6zm0-10.5c-1.6575 0-3 1.3425-3 3h1.5c0-.825.675-1.5 1.5-1.5s1.5.675 1.5 1.5c0 1.5-2.25 1.3125-2.25 3.75h1.5c0-1.6875 2.25-1.875 2.25-3.75 0-1.6575-1.3425-3-3-3z" />
    </svg>
  );
};
