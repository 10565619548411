import { ComputedPaymentTypeValues } from '@ads-bread/shared/bread/util';
import { DynamicDisclosure } from '@ads-bread/shared/bread/codecs';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getDynamicDisclosure } from '../../../lib/handlers/get-disclosure';
import { FCWithChildren } from '../../../lib/types';
import { useFetch } from '../../../lib/hooks/apiFetch';
import { useMerchantID, useProgramID } from '../../XPropsContext';
import { formattingValues } from './Intro';

type DisclaimersProps = {
  hasInstallments: boolean;
  hasSplitPay: boolean;
  installmentsPaymentTypeValues?: ComputedPaymentTypeValues | null;
  splitPayPaymentTypeValues?: ComputedPaymentTypeValues | null;
};

export const Disclaimers: FunctionComponent<DisclaimersProps> = ({
  hasInstallments,
  hasSplitPay,
  installmentsPaymentTypeValues,
  splitPayPaymentTypeValues,
}) => {
  const [disclosures, setDisclosures] = useState<DynamicDisclosure>();
  const { programID } = useProgramID();
  const { merchantID } = useMerchantID();
  const apiFetch = useFetch();

  useEffect(() => {
    const fetchDisclosures = async () => {
      if (!merchantID || !programID) {
        return;
      }

      const res = await getDynamicDisclosure(apiFetch, merchantID, programID);

      if (res.error) {
        return;
      }

      setDisclosures(res.result);
    };

    fetchDisclosures();
  }, []);

  return (
    <div data-testid="intro-disclaimer">
      <div id="footnoteDisclosureSymbolInstallments">
        <Disclaimer>
          {installmentsPaymentTypeValues && hasInstallments && (
            <FormattedMessage
              defaultMessage="<sup>{disclosureSymbolInstallments}</sup> {installmentsDisclosure}"
              description="Installment only without product price"
              values={{
                ...formattingValues,
                installmentsDisclosure: disclosures?.installmentsDisclosureText,
                disclosureSymbolInstallments:
                  disclosures?.installmentsDisclosureSymbol,
              }}
            />
          )}
        </Disclaimer>
      </div>

      {splitPayPaymentTypeValues && hasSplitPay && (
        <>
          <Disclaimer>
            <span id="footnoteDisclosureSymbolSplitPay">
              <FormattedMessage
                defaultMessage="<sup>{disclosureSymbolSplitPay}</sup> {splitPayDisclosure}"
                description="SplitPay disclaimer"
                values={{
                  ...formattingValues,
                  splitPayDisclosure: disclosures?.splitPayDisclosureText,
                  disclosureSymbolSplitPay:
                    disclosures?.splitPayDisclosureSymbol,
                }}
              />
            </span>
          </Disclaimer>
        </>
      )}
      <Disclaimer>
        <FormattedMessage
          defaultMessage="{footer}"
          description="intro disclosure footer"
          values={{
            ...formattingValues,
            footer: disclosures?.footer,
          }}
        />
      </Disclaimer>
    </div>
  );
};

export const Disclaimer: FCWithChildren = ({ children }) => {
  return (
    <p
      className="text-xs leading-normal text-dark-blue-grey leading-5 mb-4"
      style={{ whiteSpace: 'pre-line' }}
    >
      {children}
    </p>
  );
};
