import _defineProperty from "/opt/bread/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { toMoneyValue } from './money';

/**
 * Monthly payment calculator
 *
 * @remarks - ⚠️ This has not been verified for weekly frequencies.
 *
 * @param principal - Present value (PV)
 * @param rateBPS - APR in basis point format (ie 999bps is 0.0999 in decimal format)
 * @param periods - Number of payments
 * @param frequency - How often a payment is made
 *
 * @returns - Returns a calculated payment as money value.
 *
 */
export var calculatePayment = function calculatePayment(_ref) {
  var principal = _ref.principal,
      rateBPS = _ref.rateBPS,
      periods = _ref.periods,
      frequency = _ref.frequency;

  // special case for 0 APR
  if (rateBPS === 0) {
    var _payment = toMoneyValue(Math.round(gaussianBankersRound(principal / periods, 3)));

    return _payment;
  }

  var decimalRate = rateBPS / 10000;
  var intervalRate = decimalRate / (12 / frequency.length);
  var payment = principal * intervalRate / (1 - Math.pow(1 + intervalRate, -periods)); // Convert from floating point to integer, then round up

  return toMoneyValue(Math.ceil(payment));
};
/**
 * Calculates a promo offer.
 *
 * @param paymentTerms - Payment terms.
 * @param principal - The principal.
 *
 * @returns - Returns a promo offer.
 *
 */

export var calculatePromoOffer = function calculatePromoOffer(paymentTerms, principal) {
  var rate = paymentTerms.rate,
      periods = paymentTerms.numberOfPayments,
      frequency = paymentTerms.frequency;

  if (frequency.designator === 'W' && rate > 0) {
    throw new Error("Interest bearing loans with a weekly frequency are unsupported. ".concat(JSON.stringify({
      paymentTerms: paymentTerms
    }, null, '\t')));
  }

  var paymentAmount = calculatePayment({
    principal: principal.value,
    rateBPS: rate,
    periods: periods,
    frequency: frequency
  });
  return _objectSpread({
    paymentAmount: paymentAmount
  }, paymentTerms);
};
/**
 * Gaussian bankers rounding
 * @param num
 * @param decimalPlaces
 * @returns Number with specified decimal places
 */

export var gaussianBankersRound = function gaussianBankersRound(num, decimalPlaces) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors

  var i = Math.floor(n),
      f = n - i;
  var e = 1e-8; // Allow for rounding errors in f

  var r = f > 0.5 - e && f < 0.5 + e ? i % 2 === 0 ? i : i + 1 : Math.round(n);
  return d ? r / m : r;
};