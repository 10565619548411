import React, {
  FunctionComponent,
  useState,
  ReactNode,
  ReactElement,
  useRef,
} from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { useIntl } from 'react-intl';
import { FCWithChildren } from '../lib/types';

type TooltipProps = {
  tip: ReactNode;
  children: ReactElement;
  name?: string;
  id?: string;
  placement?: Placement;
  offset?: [number, number];
  tipClassName?: string;
  triggerClassName?: string;
  containerClassNames?: string;
  dataTestID?: string;
  element?: HTMLElement | string;
  active?: boolean;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  active = true,
  children,
  name,
  tip,
  id,
  placement = 'top',
  tipClassName = 'w-72',
  offset = [0, 10],
  triggerClassName = '',
  containerClassNames = 'inline',
  dataTestID,
}) => {
  const intl = useIntl();
  const timeOutRef = useRef<NodeJS.Timeout>();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [ref, setRef] = useState<HTMLSpanElement | null>(null);
  const [popper, setPopper] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(ref, popper, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
    placement,
  });

  if (!active) {
    return children;
  }

  const popperPlacement = attributes.popper
    ? attributes.popper['data-popper-placement']
    : '';
  const arrowBottom = `-bottom-1`;
  const arrowTop = `-top-1`;
  const arrowPlacement =
    popperPlacement && popperPlacement.includes('top') ? arrowBottom : arrowTop;
  const timeoutDuration = 120;

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen &&
        document.activeElement === triggerRef.current &&
        triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover as="span">
      {({ open }) => (
        <div
          className={containerClassNames}
          ref={setRef}
          onFocus={() => {
            handleEnter(open);
          }}
          onBlur={() => {
            handleLeave(open);
          }}
        >
          <Popover.Button
            ref={triggerRef}
            className={`whitespace-normal cursor-pointer ${triggerClassName}`}
            data-testid={dataTestID}
            name={name}
            role="button"
            tabIndex={0}
            aria-label={intl.formatMessage(
              {
                defaultMessage:
                  '{hasName, select, true {Click here for more information on {name}} other {Click here for more information}} ',
                description: 'Tooltip description',
              },
              {
                name: name,
                hasName: true,
              }
            )}
          >
            {children}
          </Popover.Button>
          <Popover.Panel
            role="tooltip"
            id={id}
            ref={setPopper}
            style={styles.popper}
            {...attributes.popper}
            className={`whitespace-normal rounded bg-white shadow-tooltip text-blackberry p-5 z-[60] ${tipClassName}`}
          >
            {tip}
            <div
              ref={setArrowRef}
              style={styles.arrow}
              {...attributes.popper}
              className={arrowPlacement}
            >
              <div
                className={`transform -rotate-45 w-2.5 h-2.5 bg-white p-3 border-solid border-transparent`}
              ></div>
            </div>
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export const TooltipHeading: FCWithChildren = ({ children }) => (
  <h3 className="text-base text-left mb-2 font-bold">{children}</h3>
);

export const TooltipParagraph: FCWithChildren = ({ children }) => (
  <p className="text-sm text-left font-normal leading-5">{children}</p>
);
