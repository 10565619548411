import {
  string,
  PaymentAgreementDocument,
  DynamicDisclosure,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, toErrorResponse, toResultResponse } from './base';

export const CHECKSUM_HEADER_KEY = 'x-bread-checksum';
export const PAYMENT_AGREEMENT_URI = '/api/disclosure/payment-agreement';
export const DYNAMIC_DISCLOSURE_URI =
  '/api/experience/dynamic-financing/dynamic-disclosure';

export async function getDisclosure(
  disclosureName: string,
  agreementID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<PaymentAgreementDocument>> {
  const res: APIResponse<string> = await apiFetch<string>(
    `${PAYMENT_AGREEMENT_URI}/${agreementID}/views/${disclosureName}`,
    { method: 'GET' },
    string
  );
  if (res.error) {
    return toErrorResponse(res.error);
  }
  return toResultResponse({
    checksum: res.response.headers[CHECKSUM_HEADER_KEY] ?? '',
    text: res.response.body,
  });
}

export async function getDynamicDisclosure(
  apiFetch: APIFetch,
  merchantId: string,
  programId: string
): Promise<HandlerResponse<DynamicDisclosure>> {
  const optionalParams = programId ? `&programId=${programId}` : '';
  const uri = `${DYNAMIC_DISCLOSURE_URI}?merchantId=${merchantId}${optionalParams}`;
  const res: APIResponse<DynamicDisclosure> = await apiFetch<DynamicDisclosure>(
    uri,
    { method: 'GET' },
    DynamicDisclosure
  );

  if (res.error) {
    return toErrorResponse(res.error);
  }

  return toResultResponse({
    ...res.response.body,
  });
}
