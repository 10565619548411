import * as io from 'io-ts';
export var PaymentPlan = io.type({
  headline: io.string,
  headlineDisclosureSymbol: io.string,
  subhead: io.string,
  subheadDisclosureSymbol: io.string
});
export var LoanTerms = io.type({
  subhead: io.string,
  subheadInstallmentDisclosureSymbol: io.string
});
export var InstallmentsDynamicDisclosure = io.type({
  installmentsDisclosureSymbol: io.string,
  installmentsDisclosureText: io.string
});
export var SplitPayDynamicDisclosure = io.partial({
  splitPayDisclosureSymbol: io.string,
  splitPayDisclosureText: io.string
});
export var DynamicDisclosureFooter = io.type({
  footer: io.string
});
export var DynamicDisclosure = io.intersection([InstallmentsDynamicDisclosure, SplitPayDynamicDisclosure, DynamicDisclosureFooter]);